import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import AwardItem from '~2-components/AwardItem/AwardItem';

const AwardItemKontent = (props) => {
    const { system } = props;
    const { id: awardId } = system;

    const awardData = useStaticQuery(graphql`
        query allAwards {
            allKontentItemAward {
                nodes {
                    system {
                        id
                        type
                    }
                    elements {
                        award_description {
                            value
                        }
                        award_recipient {
                            value
                        }
                        title {
                            value
                        }
                    }
                }
            }
        }
    `);

    const match = useMemo(() => (
        awardData.allKontentItemAward.nodes.find((n) => awardId === n.system.id)
    ), [ awardData, awardId]);

    const {
        title,
        award_recipient,
        award_description,
    } = match.elements;

    return (
        <AwardItem
            title={title.value}
            recipient={award_recipient.value}
            description={award_description.value}
        />
    );
};

export default AwardItemKontent;

AwardItemKontent.propTypes = {
    system: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
};

import React from 'react';
import PropTypes from 'prop-types';

const AwardItem = (props) => {
    const {
        title,
        recipient,
        description,
    } = props;

    return (
        <div className="award-item">
            <p>{title}</p>
            <h4>{recipient}</h4>
            {description &&
                <p className="award-description">{description}</p>
            }
        </div>
    );
};

export default AwardItem;

AwardItem.propTypes = {
    title: PropTypes.string.isRequired,
    recipient: PropTypes.string.isRequired,
    description: PropTypes.string,
};

export const AwardsAndAccoladesData = {
    backgroundImage: {
        mobileImage: 'https://source.unsplash.com/featured/767x664?people',
        tabletImage: 'https://source.unsplash.com/featured/1023x800?people',
        desktopImage: 'https://source.unsplash.com/featured/1600x800?people',
        description: 'this is alt text',
    },
    column1Awards: [
        {
            title: 'Leading law firm',
            recipient: 'Victorian Work Injury & Accident Compensation for 2018 – 2019.',
        },
        {
            title: 'Leading practitioner',
            recipient: 'Partner, Bree Knoester',
            description: 'Victorian Work Injury Compensation for 2017 – 2019.',
        },
        {
            title: 'Leading practitioner',
            recipient: 'Partner, Bree Knoester',
            description: 'Australian Work Injury Compensation for 2019.',
        },
    ],
    column2Awards: [
        {
            title: 'Lawyer of the year',
            recipient: 'Partner, Bree Knoester',
            description: 'Personal Injury Litigation (2020).',
        },
        {
            title: 'Leading practitioner',
            recipient: 'Partner, Bree Knoester',
            description: 'Personal Injury Litigation (2019-2020).',
        },
    ],
};

export const AwardItemsData = [
    {
        title: 'Leading law firm',
        recipient: 'Victorian Work Injury & Accident Compensation for 2018 – 2019.',
    },
    {
        title: 'Leading practitioner',
        recipient: 'Partner, Bree Knoester',
        description: 'Victorian Work Injury Compensation for 2017 – 2019.',
    },
    {
        title: 'Leading practitioner',
        recipient: 'Partner, Bree Knoester',
        description: 'Australian Work Injury Compensation for 2019.',
    },
    {
        title: 'Lawyer of the year',
        recipient: 'Partner, Bree Knoester',
        description: 'Personal Injury Litigation (2020).',
    },
    {
        title: 'Leading practitioner',
        recipient: 'Partner, Bree Knoester',
        description: 'Personal Injury Litigation (2019-2020).',
    },
];

import React from 'react';
import { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Prism from 'prismjs';
import SgLayout from '~layouts/sg-layout';
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import AwardItem from '~2-components/AwardItem/AwardItem';
import { AwardItemsData } from '~data/awardsAndAccoladesData';
import AwardItemKontent from '~3-data-components/AwardItemKontent';

const awardItemEg = `import AwardItem from '~2-components/AwardItem/AwardItem';

<AwardItem
    // Props data, CMS data handled by AwardsAndAccolades
    title='' // Required
    recipient='' // Required
    description='' // Optional
/>
`;

const SgAwardItem = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const allAwards = useStaticQuery(graphql`
        query SGallAwardItems {
            allKontentItemAward {
                nodes {
                    system {
                        id
                        type
                    }
                    elements {
                        award_description {
                            value
                        }
                        award_recipient {
                            value
                        }
                        title {
                            value
                        }
                    }
                }
            }
        }
    `);

    const allAwardItems = allAwards.allKontentItemAward.nodes;

    return (
        <SgLayout>
            <section className='sg-content'>
                <div className='container'>
                    <h1>Award Items</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={awardItemEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    {AwardItemsData.map((item, i) => {
                        return <AwardItem key={i} {...item} />;
                    })}
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>All LIVE Kontent awards & accolades</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    {allAwardItems.map((item, i) => {
                        return <AwardItemKontent key={i} {...item}/>;
                    })}
                </div>
            </section>
        </SgLayout>
    );
};

export default SgAwardItem;
